@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
    font-family: 'Roboto', sans-serif;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 16px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 16px !important;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  border-radius: 16px !important;
}